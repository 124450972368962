<template>
    <van-nav-bar title="Webpush" />
    <van-cell-group inset>
        <van-notice-bar text="ホーム画面に追加してください。" left-icon="warn-o"/>
    </van-cell-group>  
</template>

<script setup>
    import { db } from '@/db';
    db.delete();
</script>