<template>
    <van-nav-bar :title="friend.name" left-text="Back" left-arrow @click-left="back"/>
    <van-space direction="vertical" fill>
        <van-form @submit="push">
            <van-cell-group inset>
                <van-field
                v-model="body"
                name="body"
                label="Message"
                placeholder="Message"
                type="textarea"
                autosize
                rows="3"
                :rules="[{ required: true, message: 'Message is required' }]"
                />
            </van-cell-group>
            <div style="margin: 16px;">
                <van-button round block type="primary" native-type="submit" icon="chat">
                Submit
                </van-button>
            </div>
        </van-form>
        <van-cell-group inset>
            <van-cell v-for="message in messages" :key="message.uuid" :label="message.pushed ? '1':'0'">
                <template #title>
                    <span class="custom-title">{{message.text}}</span>
                </template>
                <template #right-icon>
                    {{message.type}}
                </template>
            </van-cell>
        </van-cell-group>
    </van-space>
</template>

<script setup>
    import { useRouter } from 'vue-router';
    import { db , useIndexedDB} from '@/db';
    import { showNotify } from 'vant';
    import { ref } from 'vue';
    import { v4 as uuidv4 } from 'uuid';
    import { useObservable } from '@vueuse/rxjs';
    import { liveQuery } from 'dexie';
    const router = useRouter();

    const uuid = await useIndexedDB('uuid', uuidv4());
    const body = ref('');

    function back(){
        router.push('/main');
    }


    const friend = await db.friends.get({ uuid: router.currentRoute.value.params.uuid });
    const messages = useObservable(liveQuery(() => db.messages.where({ friend_id: router.currentRoute.value.params.uuid }).reverse().toArray()));

    function urlBase64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);
        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }

    const register = await navigator.serviceWorker.getRegistration();

    let subscription = await register.pushManager.getSubscription();
    if(register.active && register.active.state !== 'activated'){
        await navigator.serviceWorker.ready;
    }
   
    if(!subscription){
        const appServerKey = await(await fetch('https://xs327810.xsrv.jp/api/key')).json();
        const applicationServerKey = urlBase64ToUint8Array(appServerKey);
        subscription = await register.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey
        });
    }
    async function push(){
        const post_id = uuidv4();
        const posted = Date.now();
        const response = await fetch('https://xs327810.xsrv.jp/api/push', {
            method: 'POST', 
            body: JSON.stringify({
                subscription:{
                    endpoint: friend.endpoint,
                    publicKey: friend.publicKey,
                    authToken: friend.authToken,
                },
                payload:{
                    title:'Message',
                    options:{
                        body: body.value,
                        data:{
                            url:`push/${uuid.value}`,
                            sender: {
                                uuid: uuid.value,
                                posted : posted,
                                endpoint: subscription.endpoint,
                                publicKey: btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('p256dh')))),
                                authToken: btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('auth')))),
                            }
                        },
                        image:'',
                        icon:'',
                        tag: post_id
                    }
                }
            })
        });
        const json = await response.json();
        if(json.success){
            await db.messages.add({
                uuid: post_id,
                friend_id: friend.uuid,
                type: 'send',
                text: body.value,
                posted: posted
            });
            showNotify({ type: 'success', message: 'Post success' });
        }else{
            showNotify({ type: 'warning', message: 'Post error' });
        }
    }
</script>