<template>
    <van-nav-bar title="Webpush" left-text="Back" left-arrow @click-left="back"/>
    <div style="text-align: center;">
        <vue-qrcode style="max-width:100%" :value="qr" :color="{ dark: '#000000ff', light: '#ffffffff' }" type="image/png"></vue-qrcode>
    </div>
</template>

<script setup>
//    import {useIndexedDB} from '@/db';
    import VueQrcode from 'vue-qrcode'
    import { ref } from 'vue';
    import { useRouter } from 'vue-router';
    const router = useRouter();
    const qr = ref('');

    // const uuid = await useIndexedDB('uuid');
    // const name = await useIndexedDB('name');

    // const register = await navigator.serviceWorker.getRegistration();
    // const subscription = await register.pushManager.getSubscription();
    
    qr.value = `https://xs327810.xsrv.jp`;//?uuid=${uuid.value}&name=${name.value}&endpoint=${subscription.endpoint}&publicKey=${btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('p256dh'))))}&authToken=${btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('auth'))))}`;

    function back(){
        router.push('/main');
    }
</script>