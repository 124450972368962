<template>
    <Suspense>
      <router-view/>
    </Suspense>
</template>

<script setup>
</script>

<style>
  body{
    background-color: #f5f5f5;
  }
  .van-nav-bar{
    margin-bottom: 16px;
  }
</style>