<template>
    <van-nav-bar style="margin-bottom: 0;" title="Webpush" @click-left="showShare = true" @click-right="refresh">
        <template #left>
            <van-icon name="share-o" />
        </template>
        <template #right>
            <van-icon name="cross" />
        </template>
    </van-nav-bar>
    <van-grid clickable :column-num="2" style="margin-bottom: 16px;">
        <van-grid-item icon="qr" text="Your Endpoint" to="/me" />
        <van-grid-item icon="scan" text="Scan Endpoint"  to="/reader" />
    </van-grid>
    <van-space direction="vertical" fill>
        <van-cell-group inset>
            <van-field v-model="name" label="Name" placeholder="Name"/>
            <van-field v-model="uuid" label="UUID" placeholder="UUID" readonly/>
        </van-cell-group>
        <van-cell-group inset>
            <van-cell is-link v-for="friend in friends" :key="friend.uuid" :title="friend.name" :label="friend.uuid" :to="`push/${friend.uuid}`"/>
        </van-cell-group>
    </van-space>
    <van-share-sheet v-model:show="showShare" :options="options" @select="onSelect" />
</template>

<script setup>
    import { ref } from 'vue';
    import { useRouter } from 'vue-router';
    import { v4 as uuidv4 } from 'uuid';
    import { db , useIndexedDB} from '@/db';
    import { liveQuery } from "dexie";
    import { useObservable } from "@vueuse/rxjs";
	const router = useRouter();

    const friends = useObservable(liveQuery(() => db.friends.toArray()));

    const uuid = await useIndexedDB('uuid', uuidv4());
    const name = await useIndexedDB('name', 'Your Name');

    const showShare = ref(false);
    const options = [
      { name: 'Link', icon: 'link', id:"duplication" }
    ];
    const onSelect = (item) => {
        router.push(`/${item.id}`);
        showShare.value = false;
    };

    function urlBase64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);
        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }

    const register = await navigator.serviceWorker.getRegistration();

    let subscription = await register.pushManager.getSubscription();
    if(register.active && register.active.state !== 'activated'){
        await navigator.serviceWorker.ready;
    }
   
    if(!subscription){
        const appServerKey = await(await fetch('https://xs327810.xsrv.jp/api/key')).json();
        const applicationServerKey = urlBase64ToUint8Array(appServerKey);
        subscription = await register.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey
        });
    }

    async function refresh(){
        await db.delete();
        location.reload();
    }

</script>