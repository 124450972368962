<template>
    <van-nav-bar title="Webpush" left-text="Back" left-arrow @click-left="back"/>
    <qrcode-stream @decode="onDecode" @init="onInit"></qrcode-stream>
</template>
<script setup>
    import { QrcodeStream} from 'vue3-qrcode-reader'
    import { useRouter } from 'vue-router';
    import { db, useIndexedDB } from '@/db';
    const router = useRouter();
    function back(){
        router.push('/main');
    }
    const uuid = await useIndexedDB('uuid');
    const name = await useIndexedDB('name');

    async function onDecode(content){
        try{
            const data = JSON.parse(content);
            if(data.endpoint && data.publicKey && data.authToken && data.uuid && data.name){
              if (!await db.friends.get(data.uuid)) {
                const register = await navigator.serviceWorker.getRegistration();
                const subscription = await register.pushManager.getSubscription();
                const response = await fetch('https://xs327810.xsrv.jp/api/push', {
                    method: 'POST', 
                    body: JSON.stringify({
                      subscription:{
                        endpoint: data.endpoint,
                        publicKey: data.publicKey,
                        authToken: data.authToken,
                      },
                      payload:{
                        type:'friend',
                        uuid: uuid.value,
                        name: name.value,
                        endpoint: subscription.endpoint,
                        publicKey: btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('p256dh')))),
                        authToken: btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('auth')))),
                      }
                    })
                });
                const json = await response.json();
                if(json.success){
                  await db.friends.put({
                    uuid: data.uuid,
                    name: data.name,
                    endpoint: data.endpoint,
                    publicKey: data.publicKey,
                    authToken: data.authToken,
                  });
                }
              }
              router.push('/main');
            }
        }catch(e){
            console.log(e);
        }
    }

    async function onInit (promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = "ERROR: you need to grant camera access permission"
        } else if (error.name === 'NotFoundError') {
          this.error = "ERROR: no camera on this device"
        } else if (error.name === 'NotSupportedError') {
          this.error = "ERROR: secure context required (HTTPS, localhost)"
        } else if (error.name === 'NotReadableError') {
          this.error = "ERROR: is the camera already in use?"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "ERROR: installed cameras are not suitable"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "ERROR: Stream API is not supported in this browser"
        } else if (error.name === 'InsecureContextError') {
          this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      }
    }
</script>