import Dexie from 'dexie';
import { ref, watch } from 'vue';
                       
const db = new Dexie('webpush');
db.version(1).stores({
	settings: '&key, value',
    friends:'++id, &uuid, name, endpoint, publicKey, authToken',
    messages:'++id, &uuid, friend_id, type, text, posted, pushed, readed',
});

async function useIndexedDB(key, defaultValue) {
    const value = ref(defaultValue);

    const result = await db.settings.get(key);
    if (result) {
        value.value = result.value;
    } else {
        await db.settings.add({ key, value: defaultValue });
    }

    watch(value, async (newValue) => {
        if (await db.settings.get(key)) {
            await db.settings.update(key, { value: newValue });
        } else {
            await db.settings.add({ key, value: newValue });
        }
    });

    return value;
}

export { db, useIndexedDB } ;