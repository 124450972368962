<template>
    <van-nav-bar title="Webpush" />
    <van-cell-group inset>
        <van-button round block type="primary" @click="requestPermission">
            Permission
        </van-button>
    </van-cell-group>
</template>

<script setup>
    import { useRouter } from 'vue-router';
	const router = useRouter();
    function requestPermission(){
        Notification.requestPermission().then(function(result) {
            if (result === 'denied') {
                console.log('Permission wasn\'t granted. Allow a retry.');
                return;
            }
            if (result === 'default') {
                console.log('The permission request was dismissed.');
                return;
            }
            router.push('/main');
            console.log('Permission was granted for notifications');
        });
    }
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if(!window.matchMedia('(display-mode: standalone)').matches && isIOS && isSafari){
        router.push('/install');
    }else if(Notification.permission == 'granted' ){
        router.push('/main');
    }
</script>