import { createApp } from 'vue'
import router from './router'
import Vant from 'vant'
import 'vant/lib/index.css'
import App from './App.vue'
import './registerServiceWorker'

const app = createApp(App);
app.use(router);
app.use(Vant);
app.mount('#app');
