import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/HomePage.vue'
import Main from '@/views/MainPage.vue'
import Me from '@/views/MePage.vue'
import Reader from '@/views/ReaderPage.vue'
import Install from '@/views/InstallPage.vue'
import Duplication from '@/views/DuplicationPage.vue'
import Push from '@/views/PushPage.vue'

const routes = [
    {
      path: '',
      name: 'home',
      component: Home,
    },{
      path: '/main',
      name: 'main',
      component: Main,
    },{
      path: '/me',
      name: 'me',
      component: Me,
    },{
      path: '/reader',
      name: 'reader',
      component: Reader,
    },{
      path: '/install',
      name: 'install',
      component: Install,
    },{
      path: '/duplication',
      name: 'duplication',
      component: Duplication,
    },{
      path: '/duplication',
      name: 'duplication',
      component: Duplication,
    },{
      path: '/push/:uuid',
      name: 'push',
      component: Push,
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router