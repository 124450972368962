<template>
    <van-nav-bar title="Webpush" left-text="Back" left-arrow @click-left="back"/>
    <div style="text-align: center;">
        <vue-qrcode style="max-width:100%" :value="qr" :color="{ dark: '#000000ff', light: '#ffffffff' }" type="image/png"></vue-qrcode>
    </div>
</template>

<script setup>
    import {useIndexedDB} from '@/db';
    import VueQrcode from 'vue-qrcode'
    import { ref } from 'vue';
    import { useRouter } from 'vue-router';
    const router = useRouter();
    const qr = ref('');
    const uuid = await useIndexedDB('uuid');
    const name = await useIndexedDB('name');

    const register = await navigator.serviceWorker.getRegistration();
    const subscription = await register.pushManager.getSubscription();
   
    qr.value = JSON.stringify({
        uuid: uuid.value,
        name: name.value,
        endpoint: subscription.endpoint,
        publicKey: btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('p256dh')))),
        authToken: btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('auth')))),
    });

    navigator.serviceWorker.addEventListener("message", function(event) {
        if(event.data === 'add friend'){
            router.push('/main');
        }
        router.push('/main');
    });
    
    function back(){
        router.push('/main');
    }
</script>